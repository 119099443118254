var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("m-base-card", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(_vm._s(_vm.item.item_title))]
        },
        proxy: true,
      },
      {
        key: "card-actions",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  color: "red accent-2",
                  icon: "",
                  loading: _vm.removing,
                },
                on: { click: _vm.removeItem },
              },
              [_c("v-icon", [_vm._v("delete")])],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "col-1",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex justify-center" }, [
              _c("span", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(!_vm.$language ? "PRICE: " : "মূল্য: ")),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.item.price || "0"))]),
              _vm._v(_vm._s(_vm.item.currency) + " "),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "col-2",
        fn: function () {
          return [
            _c(
              "v-container",
              [
                _c("v-row", { staticClass: "ma-0" }, [
                  _c("span", { staticClass: "font-weight-medium" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.$language ? "Offered By: " : "কোর্স অফার করেছে: "
                      ) + _vm._s(_vm.item.item_body.teacher)
                    ),
                  ]),
                ]),
                _c(
                  "v-row",
                  {
                    staticClass: "ma-0 mt-4",
                    attrs: { justify: "space-between" },
                  },
                  [
                    _c(
                      "m-base-card-info",
                      { attrs: { label: !_vm.$language ? "Session" : "সেশন" } },
                      [
                        _vm._v(
                          _vm._s(
                            !_vm.$language
                              ? _vm.item.item_body.begin_date
                              : new Date(
                                  _vm.item.item_body.begin_date
                                ).toLocaleString("bn-BD", {
                                  dateStyle: "medium",
                                })
                          ) +
                            " - " +
                            _vm._s(
                              !_vm.$language
                                ? _vm.item.item_body.end_date
                                : new Date(
                                    _vm.item.item_body.end_date
                                  ).toLocaleString("bn-BD", {
                                    dateStyle: "medium",
                                  })
                            )
                        ),
                      ]
                    ),
                    _c(
                      "m-base-card-info",
                      {
                        attrs: {
                          label: !_vm.$language
                            ? "Number of Students"
                            : "শিক্ষার্থী সংখ্যা",
                        },
                      },
                      [_vm._v(_vm._s(_vm.item.item_body.num_students))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }