// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cart-package-card {\n  max-height: 210px !important;\n  background: #ffffff;\n}\n.cart-package-card .card-img {\n  background: #377dff;\n  filter: blur(0.6px) brightness(120%);\n  border-radius: 10px !important;\n  opacity: 0.7;\n}\n.cart-package-card h3 {\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 20px;\n  line-height: 22px;\n  color: #3d3d3d;\n}\n.cart-package-card p {\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  color: #3d3d3d;\n}\n.cart-package-card .card-content {\n  min-height: 100px !important;\n  max-height: 110px !important;\n}\n.cart-package-card .card-content .cart-package-cost p {\n  font-family: Poppins, serif;\n  font-size: 18px;\n  color: #ff7c45;\n}\n.on-hover-cart-item h3 {\n  color: #0099dc;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
