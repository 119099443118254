<template>
  <m-base-card>
    <template v-slot:title>{{ item.item_title }}</template>
    <template v-slot:card-actions
      ><v-btn color="red accent-2" icon :loading="removing" @click="removeItem">
        <v-icon>delete</v-icon></v-btn
      ></template
    >
    <template v-slot:col-1>
      <div class="d-flex justify-center">
        <span class="mr-1">{{ !$language ? "PRICE: " : "মূল্য: " }}</span>
        <span>{{ item.price || "0" }}</span
        >{{ item.currency }}
      </div>
    </template>
    <template v-slot:col-2>
      <v-container>
        <v-row class="ma-0">
          <span class="font-weight-medium"
            >{{ !$language ? "Offered By: " : "কোর্স অফার করেছে: "
            }}{{ item.item_body.teacher }}</span
          >
        </v-row>
        <v-row justify="space-between" class="ma-0 mt-4">
          <m-base-card-info :label="!$language ? 'Session' : 'সেশন'"
            >{{
              !$language
                ? item.item_body.begin_date
                : new Date(item.item_body.begin_date).toLocaleString("bn-BD", {
                    dateStyle: "medium",
                  })
            }}
            -
            {{
              !$language
                ? item.item_body.end_date
                : new Date(item.item_body.end_date).toLocaleString("bn-BD", {
                    dateStyle: "medium",
                  })
            }}</m-base-card-info
          >
          <m-base-card-info
            :label="!$language ? 'Number of Students' : 'শিক্ষার্থী সংখ্যা'"
            >{{ item.item_body.num_students }}</m-base-card-info
          >
        </v-row>
      </v-container>
    </template>
  </m-base-card>
</template>

<script>
import MBaseCard from "~ef/components/MBaseCard";
import MBaseCardInfo from "~ef/components/MBaseCardInfo";
export default {
  name: "CartCard",
  components: { MBaseCardInfo, MBaseCard },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      removing: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    async removeItem() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.removing = true;
        await this.$store.dispatch("cart/removeItem", {
          id: this.item.item_id,
        });
      } catch (e) {
        this.$displayError(e);
        this.$logger.log(e);
      } finally {
        this.removing = false;
      }
    },
  },
};
</script>

<style lang="scss">
.cart-package-card {
  max-height: 210px !important;
  background: #ffffff;

  .card-img {
    background: #377dff;
    filter: blur(0.6px) brightness(120%);
    border-radius: 10px !important;
    opacity: 0.7;
  }
  h3 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: #3d3d3d;
  }
  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #3d3d3d;
  }
  .card-content {
    min-height: 100px !important;
    max-height: 110px !important;

    .cart-package-cost {
      p {
        font-family: Poppins, serif;
        font-size: 18px;
        color: #ff7c45;
      }
    }
  }
}
.on-hover-cart-item {
  h3 {
    color: #0099dc;
  }
}
</style>
