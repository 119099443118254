<template>
  <m-container>
    <page-title
      previous-location
      :page-title="!$language ? 'Cart' : 'কার্ট'"
      class="mt-5"
    ></page-title>
    <m-container v-if="cart">
      <template v-if="fetchingItems">
        <div id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      </template>
      <no-content-component
        v-else-if="cart.items?.length === 0"
        :message="noContentMessage"
      ></no-content-component>
      <m-row v-else>
        <m-col cols="8">
          <h3 v-if="!$language" class="cart-package-count mb-3">
            {{ cart.items.length }}
            {{ cart.items.length === 1 ? "batch" : "batches" }} in the cart
          </h3>
          <h3 v-else class="cart-package-count mb-3">
            কার্টে {{ cart.items.length }} টা ব্যাচ আছে
          </h3>
          <v-list class="cart-list py-0">
            <cart-card
              v-for="item in cart.items"
              :key="item.item_id"
              :item="item"
              @remove="remove(item)"
            ></cart-card>
          </v-list>
        </m-col>
        <m-col
          class="d-flex align-center justify-center"
          style="height: 100%; position: sticky; top: 80px"
        >
          <v-card height="300px" width="100%" elevation="0">
            <v-card-text>
              <div
                style="height: 210px"
                class="d-flex flex-column justify-center align-center"
              >
                <h2 class="cart-package-count">
                  {{ !$language ? "Total:" : "সর্বমোট:" }}
                </h2>
                <h2>{{ cart.price }} {{ cart.currency }}</h2>
              </div>
              <v-row align="center">
                <v-col>{{ !$language ? "Coupon code" : "কুপন কোড" }}</v-col>
                <v-col
                  ><v-text-field
                    v-model="couponCode"
                    outlined
                    @change="queryCouponCart"
                  ></v-text-field
                ></v-col>
              </v-row>
              <p>
                {{ !$language ? "Updated amount " : "আপডেট পরিমাণ "
                }}{{ cart.price - discount }}
              </p>
              <v-btn
                color="primary"
                depressed
                block
                :disabled="cart.items.length === 0"
                @click="checkout"
                >{{ !$language ? "Checkout" : "চেকআউট" }}</v-btn
              >
            </v-card-text>
          </v-card>
        </m-col>
      </m-row>
    </m-container>
  </m-container>
</template>

<script>
import moment from "moment";
import CartCard from "#ef/cart/components/CartCard.vue";
import { CartApi, PaymentApi } from "../../modules/cart/api";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "Cart",
  components: { CartCard, NoContentComponent },
  mixins: [],
  props: {},
  data() {
    return {
      fetchingItems: undefined,
      couponCode: "",
      discount: 0,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart.cart;
    },
    noContentMessage() {
      return !this.$language
        ? "There are no items in your cart"
        : "আপনার কার্টে এই মুহূর্তে কিছু নেই";
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      return;
    }

    try {
      this.fetchingItems = true;
      await this.$store.dispatch("cart/get");
      if (this.$route.query.item_id) {
        this.item = {
          course_id: this.$route.query.course_id,
          coupon_type: this.$route.query.coupon_type,
          partner_code: this.$route.query.partner_code,
          item_id: this.$route.query.item_id,
          item_title: this.$route.query.item_name,
          item_body: {
            teacher_name: this.$route.query.teacher_name,
            begin_date: this.$route.query.begin_date,
            end_date: this.$route.query.end_date,
          },
        };
        await this.addToCart(this.item);
      }
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message]);
    } finally {
      this.fetchingItems = false;
    }
  },
  methods: {
    async checkout() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      const order_id = await this.$store.dispatch("cart/checkout", {
        code: this.couponCode,
      });
      await this.$router.push("/students/checkout/" + order_id, {
        params: { coupon_code: this.couponCode },
      });
      //await this.$router.push({ path: "/checkout/" + order_id + "/" + this.couponCode});
    },
    async addToCart(item) {
      let currentDate = new Date().toISOString().split("T")[0];
      if (moment(item.end_date).isBefore(currentDate, "day")) {
        this.$root.$emit("alert", [
          undefined,
          !this.$language ? "This batch is expired" : "এই ব্যাচের মেয়াদ শেষ",
        ]);
        return;
      }
      try {
        await this.$store.dispatch("cart/addItem", {
          item,
        });
        this.$emit("cancel");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async queryCouponCart() {
      if (this.cart.items.length < 1) {
        // not checking the coupon legitimacy if there is no items in the cart,
        // but why it only ties to the first element in the cart I have got no clue from Rifad although he did it,
        // skipping the speculation for now
        return;
      }
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      try {
        let resp = await this.$store.dispatch("cart/queryCoupon", {
          code: this.couponCode,
          coupon_inserted_from: "cart",
        });

        if (resp) {
          this.discount =
            resp.coupon_type === "flat"
              ? resp.amount
              : (this.cart.price * resp.amount) / 100;
        }
      } catch (e) {
        this.discount = 0;
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style lang="scss">
.cart-list {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.cart-package-count {
  font-family: Poppins, serif;
  font-weight: normal;
  color: $text;
  font-size: 16px;
}
.purchase-content {
  margin-top: 15px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  p {
    font-family: Poppins, serif;
    text-align: center;
    color: $text;
  }
}
</style>
