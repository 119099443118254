var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "previous-location": "",
          "page-title": !_vm.$language ? "Cart" : "কার্ট",
        },
      }),
      _vm.cart
        ? _c(
            "m-container",
            [
              _vm.fetchingItems
                ? [
                    _c("div", { attrs: { id: "loading-wrapper" } }, [
                      _c("div", { attrs: { id: "loading-text" } }, [
                        _vm._v("LOADING"),
                      ]),
                      _c("div", { attrs: { id: "loading-content" } }),
                    ]),
                  ]
                : _vm.cart.items?.length === 0
                ? _c("no-content-component", {
                    attrs: { message: _vm.noContentMessage },
                  })
                : _c(
                    "m-row",
                    [
                      _c(
                        "m-col",
                        { attrs: { cols: "8" } },
                        [
                          !_vm.$language
                            ? _c(
                                "h3",
                                { staticClass: "cart-package-count mb-3" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.cart.items.length) +
                                      " " +
                                      _vm._s(
                                        _vm.cart.items.length === 1
                                          ? "batch"
                                          : "batches"
                                      ) +
                                      " in the cart "
                                  ),
                                ]
                              )
                            : _c(
                                "h3",
                                { staticClass: "cart-package-count mb-3" },
                                [
                                  _vm._v(
                                    " কার্টে " +
                                      _vm._s(_vm.cart.items.length) +
                                      " টা ব্যাচ আছে "
                                  ),
                                ]
                              ),
                          _c(
                            "v-list",
                            { staticClass: "cart-list py-0" },
                            _vm._l(_vm.cart.items, function (item) {
                              return _c("cart-card", {
                                key: item.item_id,
                                attrs: { item: item },
                                on: {
                                  remove: function ($event) {
                                    return _vm.remove(item)
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "m-col",
                        {
                          staticClass: "d-flex align-center justify-center",
                          staticStyle: {
                            height: "100%",
                            position: "sticky",
                            top: "80px",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                height: "300px",
                                width: "100%",
                                elevation: "0",
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column justify-center align-center",
                                      staticStyle: { height: "210px" },
                                    },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "cart-package-count" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                !_vm.$language
                                                  ? "Total:"
                                                  : "সর্বমোট:"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.cart.price) +
                                            " " +
                                            _vm._s(_vm.cart.currency)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { align: "center" } },
                                    [
                                      _c("v-col", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$language
                                              ? "Coupon code"
                                              : "কুপন কোড"
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            attrs: { outlined: "" },
                                            on: { change: _vm.queryCouponCart },
                                            model: {
                                              value: _vm.couponCode,
                                              callback: function ($$v) {
                                                _vm.couponCode = $$v
                                              },
                                              expression: "couponCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "Updated amount "
                                            : "আপডেট পরিমাণ "
                                        ) +
                                        _vm._s(_vm.cart.price - _vm.discount) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        depressed: "",
                                        block: "",
                                        disabled: _vm.cart.items.length === 0,
                                      },
                                      on: { click: _vm.checkout },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language ? "Checkout" : "চেকআউট"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }